<template>
  <section class="dashboard">
    <div>
            <transition
            mode="in-out"
            enter-active-class="fadeIn"
            leave-active-class="fadeOut"
            appear>
                <router-view></router-view>
            </transition>
    </div>  
    <div
      id="dashboard"
      :class="[{'collapsed' : collapsed}, {'onmobile' : isOnMobile}]"
    >   
      <div class="dashboard">
        <sidebar-menu
          :menu="menu"
          :collapsed="collapsed"
          :theme="selectedTheme"
          :show-one-child="true"
          @toggle-collapse="onToggleCollapse"
          @item-click="onItemClick"
        />
        <div
          v-if="isOnMobile && !collapsed"
          class="sidebar-overlay"
          @click="collapsed = true"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu';

export default {
  name: 'dashboard',
  components: {
    'sidebar-menu': SidebarMenu,
  },
  data() {
    return {
      menu: [
        {
          header: true,
          title: 'Account Management',
          hiddenOnCollapse: true
        },
        {
          href: { name: "MyHome" },
          title: 'Home',
          icon: 'fa fa-home'
        },
        {
          href: { name: "MyProfile", params: { change: "00" } },
          title: 'Profile',
          icon: 'fa fa-user'
        },
        { href: { name: "MyBilling"},
          title: 'Billing',
          icon: 'fa fa-money'
        },
        {
          href: { name: "MyApp"},
          title: 'App Access',
          icon: 'fa fa-key'
        },
        {
          header: true,
          title: 'Contact Management',
          hiddenOnCollapse: true
        },
        {
          href: { name: "MySearch" },
          title: 'Search & Add',
          icon: 'fa fa-user-plus'
        },
        {
          href: { name: "MyInvite" },
          title: 'Invite a Contact',
          icon: 'fa fa-paper-plane'
        },
        {
          href: { name: "MyRequests" },
          title: 'Contact Requests',
          icon: 'fa fa-users',
        },
        {
          href: { name: "MyContacts" },
          title: 'My Contacts',
          icon: 'fa fa-id-card'
        },
      ],
      collapsed: false,
      themes: [
        {
          name: 'Default theme',
          input: ''
        },
        {
          name: 'White theme',
          input: 'white-theme'
        }
      ],
      selectedTheme: 'default-theme',
      isOnMobile: false
    }
  },
  computed: {
      loggedIn() {
        console.log("Login State: " + this.$store.state.auth.status.loggedIn);
        return this.$store.state.auth.status.loggedIn;
      },
  },
  created() {
    console.log("USER: ");
    console.log(this.$store.state.auth.user);
    if (this.loggedIn == false) {
      console.log("redirect...");
      this.$router.replace('/login');
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    onToggleCollapse (collapsed) {
      console.log(collapsed)
      this.collapsed = collapsed
    },
    onItemClick (event, item, node) {
      console.log('onItemClick')
      console.log(event)
      console.log(item)
      console.log(node)
    },
    onResize () {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>

@import '~animate.css';
.animated {
  animation-duration: .377s;
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600');
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  background-color: #f2f4f7;
  color: #262626;
}
#dashboard {
  //padding-left: 256px;
  width: 100%;
  height: 100%;
  min-height: 830px;
  transition: 0.3s ease;
}
#dashboard.collapsed {
  padding-left: 50px;
}
#dashboard.onmobile {
  padding-left: 50px;
}
.dashboard {
  margin-bottom: 38px;
  // padding-left: 50px;
  // max-width: 1710px;
}
/*
@media screen and (max-width: 800px) {
  .dash-container {
    margin-left: 24%;
    margin-right: 10%;
    margin-top: 40px;
  }
}
*/
// @media screen and (min-width: 1300px) {
.dash-container {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 60px;
}
//}

.sidebar-overlay {
  position: relative;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
  z-index: 900;
}
.v-sidebar-menu {
  position: absolute;
  height: 95%;
  overflow: auto;
  color: #aac734;
  padding-top: 50px;
  margin-top: 58px;
  max-width: 200px;
}
.v-sidebar-menu.vsm_expanded {
    width: 265px;
}
pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.v-sidebar-menu .vsm--arrow:after {
  content: '\f105';
  font-family: 'FontAwesome';
}
.v-sidebar-menu .vsm--toggle-btn:after {
  content: '\f07e';
  font-family: 'FontAwesome';
}
</style>